import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3307fa81"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "title"
};
const _hoisted_2 = {
  class: "tips"
};
const _hoisted_3 = {
  class: "tips-box"
};
const _hoisted_4 = {
  class: "btn-box"
};
const _hoisted_5 = {
  class: "box",
  style: {}
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_buttom_com = _resolveComponent("buttom-com");
  return _openBlock(), _createElementBlock("div", {
    class: "top-box",
    style: _normalizeStyle({
      'background': '#010D28 url(' + _ctx.ngUrl + ') center/cover no-repeat'
    })
  }, [_createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1), _createElementVNode("div", _hoisted_2, [_createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.tips), 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_buttom_com, {
    btnName: _ctx.buttomName,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.jumpPage('/bookDemoPage.html'))
  }, null, 8, ["btnName"])])])], 4);
}