import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-8e9137ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.isSubmit ? 'fuzz-button fuzz-submit flicker' : 'fuzz-button flicker'),
    type: "primary"
  }, [_createElementVNode("span", null, [_createTextVNode(_toDisplayString(_ctx.btnName) + " ", 1), _ctx.isArrow ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: require('@/assets/img/serve/arrow.svg'),
    alt: ""
  }, null, 8, _hoisted_1)) : _createCommentVNode("", true)])], 2);
}