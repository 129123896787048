import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    btnName: {
      type: String,
      default: '确定'
    },
    // 是否根据父组件大小填充
    filling: {
      type: Boolean,
      default: true
    },
    isSubmit: {
      type: Boolean,
      default: true
    },
    isArrow: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {};
  }
});